import { useState } from 'react';
import { generateHardwareLicenceARS } from '../services/api';
import { Copy, Download, Upload } from 'lucide-react';

const ARSGenerateLicence = ({ customerId, linkedProductId, licence, setLicence }) => {
  const [licenceRequest, setLicenceRequest] = useState('');
  const [generatedLicence, setGeneratedLicence] = useState(licence || '');
  const [loading, setLoading] = useState(false);
  const [showCopyNotification, setShowCopyNotification] = useState(false);
  const [error, setError] = useState('');

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      setLicenceRequest(text);
      setError('');
      
      try {
        setLoading(true);
        const response = await generateHardwareLicenceARS(customerId, linkedProductId, text);
        setGeneratedLicence(response.licence);
        setLicence(response.licence);
      } catch (error) {
        console.error('Error generating licence:', error);
        setError('Failed to generate licence');
        setGeneratedLicence('');
      } finally {
        setLoading(false);
      }
    };
    reader.readAsText(file);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedLicence);
    setShowCopyNotification(true);
    setTimeout(() => setShowCopyNotification(false), 2000);
  };

  const downloadLicence = () => {
    const blob = new Blob([generatedLicence], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'licence.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <div className="transition-all duration-300 backdrop-blur-sm p-2">
      {!generatedLicence && (
        <div>
          <label className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#233563] to-blue-500">
            Upload Licence Request
          </label>
          <div className="mt-2">
            <label 
              htmlFor="licenceRequestFile"
              className="w-full flex justify-center items-center py-3 px-6 rounded-xl text-white font-medium
                bg-gradient-to-r from-[#233563] to-blue-500 hover:from-[#1a284d] hover:to-blue-600
                transform hover:scale-[1.02] transition-all duration-300 shadow-lg hover:shadow-xl cursor-pointer"
            >
              <Upload className="w-5 h-5 mr-2" />
              Choose File
              <input
                id="licenceRequestFile"
                type="file"
                accept=".txt"
                onChange={handleFileUpload}
                className="hidden"
              />
            </label>
          </div>
        </div>
      )}

      {error && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-xl text-red-600">
          {error}
        </div>
      )}

      {(loading || generatedLicence) && (
        <div className="space-y-4">
          <label className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#233563] to-blue-500">
            Licence
          </label>
          <div className="mt-4 p-4 bg-blue-50 rounded-xl relative">
            {loading ? (
              <div className="flex justify-center items-center py-10">
                <div className="animate-spin rounded-full h-8 w-8 border-2 border-[#233563] border-t-transparent"></div>
              </div>
            ) : (
              <>
                <textarea
                  readOnly
                  value={generatedLicence}
                  className="w-full bg-transparent border-none focus:outline-none min-h-[100px] resize-none"
                />
                <div className="absolute top-2 right-2 flex space-x-2">
                  {showCopyNotification && (
                    <div className="absolute right-0 -top-8 bg-gray-800 text-white text-sm py-1 px-2 rounded shadow-lg">
                      Copied!
                    </div>
                  )}
                  <button
                    onClick={copyToClipboard}
                    className="p-2 text-gray-500 hover:text-gray-700 bg-white hover:bg-gray-100 rounded-lg transition-colors shadow-sm"
                    title="Copy to clipboard"
                  >
                    <Copy size={16} />
                  </button>
                  <button
                    onClick={downloadLicence}
                    className="p-2 text-gray-500 hover:text-gray-700 bg-white hover:bg-gray-100 rounded-lg transition-colors shadow-sm"
                    title="Download licence"
                  >
                    <Download size={16} />
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ARSGenerateLicence;
