import { useState, useEffect } from 'react';
import { getCustomerLinkedProducts, linkProductVersion, 
  editLinkedProductVersion, deleteLinkedProductVersion, 
  getProducts, getProductVersions, getLicenceTemplate, 
  editCustomer, resetHardwareLicenceARS } from '../services/api';
import { Pencil, Trash2, Plus, X, ChevronDown, ChevronUp, Check, Key, Shield } from 'lucide-react';

const CustomerProductVersionsCard = ({ key, customer, onDelete }) => {
  const [linkedProducts, setLinkedProducts] = useState([]);
  const [showLinkForm, setShowLinkForm] = useState(false);
  const [products, setProducts] = useState([]);
  const [versions, setVersions] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [licenceTemplate, setLicenceTemplate] = useState([]);
  const [editingProduct, setEditingProduct] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [showCustomerDeleteConfirm, setShowCustomerDeleteConfirm] = useState(false);
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [editedCustomerData, setEditedCustomerData] = useState({
    name: customer.name,
    email: customer.email
  });
  const [emailError, setEmailError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [formData, setFormData] = useState({
    productId: '',
    site: '',
    versionId: '',
    licenceExpiryDate: '',
    subscriptionInfo: []
  });

  useEffect(() => {
    if (!isCollapsed) {
      loadCardData();
    }
  }, [isCollapsed, customer.id]);

  const loadCardData = async () => {
    setIsLoadingProducts(true);
    try {
      await Promise.all([
        fetchLinkedProducts(),
        fetchProducts()
      ]);
    } finally {
      setIsLoadingProducts(false);
    }
  };

  const fetchLinkedProducts = async () => {
    try {
      const data = await getCustomerLinkedProducts(customer.id);
      setLinkedProducts(data);
    } catch (error) {
      console.error('Error fetching linked products:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const data = await getProducts();
      setProducts(data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleProductChange = async (e) => {
    const productId = parseInt(e.target.value);
    setSelectedProduct(productId);
    setFormData(prev => ({ ...prev, productId }));
    setIsLoadingTemplate(true);

    try {
      const [versionsData, templateData] = await Promise.all([
        getProductVersions(productId),
        getLicenceTemplate(productId)
      ]);
      setVersions(versionsData);
      setLicenceTemplate(templateData);
      setFormData(prev => ({
        ...prev,
        subscriptionInfo: templateData.map(t => ({ key: t.key, value: t.value }))
      }));
    } catch (error) {
      console.error('Error fetching product data:', error);
    } finally {
      setIsLoadingTemplate(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await linkProductVersion(customer.id, formData);
      await fetchLinkedProducts();
      setShowLinkForm(false);
      setFormData({
        productId: '',
        site: '',
        versionId: '',
        licenceExpiryDate: '',
        subscriptionInfo: []
      });
    } catch (error) {
      console.error('Error linking product:', error);
    }
  };

  const handleEdit = async (linkedProduct) => {
    setEditingProduct(linkedProduct);
    setIsLoadingTemplate(true);
    try {
      const [versionsData, templateData] = await Promise.all([
        getProductVersions(linkedProduct.productId),
        getLicenceTemplate(linkedProduct.productId)
      ]);
      setVersions(versionsData);
      setLicenceTemplate(templateData);
      
      // Merge template with existing subscription info
      const mergedSubscriptionInfo = templateData.map(template => {
        const existingInfo = linkedProduct.subscriptionInfo.find(info => info.key === template.key);
        return {
          key: template.key,
          value: existingInfo ? existingInfo.value : template.value
        };
      });
      setFormData({
        productId: linkedProduct.productId,
        site: linkedProduct.site,
        versionId: linkedProduct.versionId,
        licenceExpiryDate: linkedProduct.licenceExpiryDate ? linkedProduct.licenceExpiryDate.split('T')[0] : '', // Convert ISO date to yyyy-MM-dd format
        subscriptionInfo: mergedSubscriptionInfo
      });
      setSelectedProduct(linkedProduct.productId);
    } catch (error) {
      console.error('Error fetching product data:', error);
    } finally {
      setIsLoadingTemplate(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await editLinkedProductVersion(customer.id, editingProduct.id, formData);
      await fetchLinkedProducts();
      setEditingProduct(null);
    } catch (error) {
      console.error('Error updating linked product:', error);
    }
  };

  const handleDelete = async (productId) => {
    try {
      await deleteLinkedProductVersion(customer.id, productId);
      await fetchLinkedProducts();
      setShowDeleteConfirm(null);
    } catch (error) {
      console.error('Error deleting linked product:', error);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSaveCustomer = async () => {
    if (!validateEmail(editedCustomerData.email)) {
      setEmailError('Please enter a valid email address');
      return;
    }
    setEmailError('');

    try {
      await editCustomer(customer.id, editedCustomerData);
      customer.name = editedCustomerData.name;
      customer.email = editedCustomerData.email;
      setShowEditCustomerModal(false);
    } catch (error) {
      console.error('Error updating customer:', error);
      setEditedCustomerData({
        name: customer.name,
        email: customer.email
      });
    }
  };

  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*';
    let password = '';
    for (let i = 0; i < 12; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setNewPassword(password);
  };

  const handlePasswordReset = async () => {
    try {
      await editCustomer(customer.id, { password: newPassword });
      setShowPasswordResetModal(false);
      setNewPassword('');
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };

  const handleResetLicence = async (linkedProductId) => {
    if (!window.confirm('Are you sure you want to reset this licence? This will invalidate the current hardware licence.')) {
      return;
    }

    try {
      await resetHardwareLicenceARS(linkedProductId);
      alert('Licence reset successfully');
    } catch (error) {
      console.error('Error resetting licence:', error);
      alert('Failed to reset licence');
    }
  };

  const modalIsOpen = showEditCustomerModal || showPasswordResetModal || showCustomerDeleteConfirm || showDeleteConfirm || showLinkForm || editingProduct;

  return (
    <>
      <div className={`fixed inset-0 ${modalIsOpen ? 'bg-black bg-opacity-50 z-50' : 'pointer-events-none'}`} />

      {showEditCustomerModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-96 overflow-y-auto max-h-[80vh]">
            <h2 className="text-xl font-bold mb-4">Edit Customer</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={editedCustomerData.name}
                  onChange={(e) => setEditedCustomerData(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={editedCustomerData.email}
                  onChange={(e) => {
                    setEditedCustomerData(prev => ({ ...prev, email: e.target.value }));
                    setEmailError('');
                  }}
                  className={`w-full px-3 py-2 border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                />
                {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-6">
              <button
                onClick={() => {
                  setShowEditCustomerModal(false);
                  setEditedCustomerData({
                    name: customer.name,
                    email: customer.email
                  });
                  setEmailError('');
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveCustomer}
                className="px-4 py-2 bg-[#233563] text-white rounded-lg hover:bg-[#1a284d]"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}

      {showPasswordResetModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-96 overflow-y-auto max-h-[80vh]">
            <h2 className="text-xl font-bold mb-4">Reset Password</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  New Password
                </label>
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md"
                  />
                  <button
                    onClick={generateRandomPassword}
                    className="px-3 py-2 bg-gray-100 rounded-md hover:bg-gray-200"
                  >
                    Generate
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-6">
              <button
                onClick={() => {
                  setShowPasswordResetModal(false);
                  setNewPassword('');
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handlePasswordReset}
                className="px-4 py-2 bg-[#233563] text-white rounded-lg hover:bg-[#1a284d]"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      )}

      {showCustomerDeleteConfirm && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-bold mb-4">Confirm Delete Customer</h2>
            <p className="mb-6">Are you sure you want to delete this customer? This action cannot be undone.</p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowCustomerDeleteConfirm(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  onDelete();
                  setShowCustomerDeleteConfirm(false);
                }}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {showDeleteConfirm && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
            <p className="mb-6">Are you sure you want to delete this linked product?</p>
            <div className="flex justify-end gap-2">
              <button
                onClick={() => setShowDeleteConfirm(null)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={() => handleDelete(showDeleteConfirm)}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {(showLinkForm || editingProduct) && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-96 overflow-y-auto max-h-[80vh]">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">
                {editingProduct ? 'Edit Linked Product' : 'Link New Product'}
              </h2>
              <button
                onClick={() => {
                  setShowLinkForm(false);
                  setEditingProduct(null);
                }}
                className="text-gray-600 hover:text-gray-900"
              >
                <X size={20} />
              </button>
            </div>
            
            <form onSubmit={editingProduct ? handleUpdate : handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Product
                </label>
                <select
                  value={formData.productId}
                  onChange={handleProductChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                  disabled={editingProduct}
                >
                  <option value="">Select Product</option>
                  {products.map(product => (
                    <option key={product.id} value={product.id}>
                      {product.name}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Site
                </label>
                <input
                  type="text"
                  value={formData.site}
                  onChange={(e) => setFormData(prev => ({ ...prev, site: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>

              {selectedProduct && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Version
                  </label>
                  <select
                    value={formData.versionId}
                    onChange={(e) => setFormData(prev => ({ ...prev, versionId: parseInt(e.target.value) }))}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                    required
                  >
                    <option value="">Select Version</option>
                    {versions.map(version => (
                      <option key={version.id} value={version.id}>
                        {version.version}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  License Expiry Date
                </label>
                <input
                  type="date"
                  value={formData.licenceExpiryDate}
                  onChange={(e) => setFormData(prev => ({ ...prev, licenceExpiryDate: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                />
              </div>

              {isLoadingTemplate ? (
                <div className="flex justify-center py-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#233563]"></div>
                </div>
              ) : licenceTemplate.length > 0 && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Subscription Information
                  </label>
                  {formData.subscriptionInfo.map((info, idx) => (
                    <div key={idx} className="flex gap-2 mb-2">
                      <input
                        type="text"
                        value={info.key}
                        className="w-1/2 px-3 py-2 border border-gray-300 rounded-md bg-gray-100"
                        disabled
                      />
                      <input
                        type="text"
                        value={info.value}
                        onChange={(e) => {
                          const newSubscriptionInfo = [...formData.subscriptionInfo];
                          newSubscriptionInfo[idx].value = e.target.value;
                          setFormData(prev => ({ ...prev, subscriptionInfo: newSubscriptionInfo }));
                        }}
                        className="w-1/2 px-3 py-2 border border-gray-300 rounded-md"
                        required
                      />
                    </div>
                  ))}
                </div>
              )}

              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => {
                    setShowLinkForm(false);
                    setEditingProduct(null);
                  }}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#233563] text-white rounded-lg hover:bg-[#1a284d]"
                >
                  {editingProduct ? 'Save Changes' : 'Link Product'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex justify-between items-center">
          <button 
            onClick={() => setIsCollapsed(!isCollapsed)} 
            className="flex-1 flex justify-between items-center hover:bg-gray-50 p-2 rounded-lg"
          >
            <div className="flex items-center gap-4">
              <h2 className="text-xl font-bold">{customer.name}</h2>
              {isCollapsed && (
                <div className="flex gap-2">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowEditCustomerModal(true);
                    }}
                    className="p-1 text-gray-600 hover:text-gray-900"
                  >
                    <Pencil size={16} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowPasswordResetModal(true);
                    }}
                    className="p-1 text-gray-600 hover:text-gray-900"
                  >
                    <Key size={16} />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowCustomerDeleteConfirm(true);
                    }}
                    className="p-1 text-red-600 hover:text-red-900"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              )}
            </div>
            {isCollapsed ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
          </button>
          {!isCollapsed && (
            <button
              onClick={() => setShowLinkForm(true)}
              className="ml-4 flex items-center gap-2 bg-[#233563] text-white px-4 py-2 rounded-lg hover:bg-[#1a284d]"
            >
              <Plus size={16} /> Link Product
            </button>
          )}
        </div>

        {!isCollapsed && (
          <>
            {isLoadingProducts ? (
              <div className="flex justify-center py-8">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#233563]"></div>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
                {linkedProducts.map((product) => (
                  <div key={product.id} className="border rounded-lg p-4">
                    <div className="flex justify-between items-start mb-4">
                      <div>
                        <h3 className="font-semibold">{product.productName}</h3>
                        <p className="text-sm text-gray-600">Site: {product.site}</p>
                        <p className="text-sm text-gray-600">Version: {product.versionName}</p>
                        <p className="text-sm text-gray-600">Purchase Date: {product.purchaseDate}</p>
                        <p className="text-sm text-gray-600">Expires: {product.licenceExpiryDate || 'N/A'}</p>
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={() => handleEdit(product)}
                          className="p-2 text-gray-600 hover:text-gray-900"
                        >
                          <Pencil size={16} />
                        </button>
                        <button
                          onClick={() => setShowDeleteConfirm(product.id)}
                          className="p-2 text-red-600 hover:text-red-900"
                        >
                          <Trash2 size={16} />
                        </button>
                        {product.licence && (
                          <button
                            onClick={() => handleResetLicence(product.id)}
                            className="p-2 text-gray-600 hover:text-gray-900"
                          title="Reset Licence"
                        >
                          <Shield size={16} />
                        </button>
                        )}
                      </div>
                    </div>
                    <div className="space-y-2">
                      <h4 className="font-medium text-sm">Subscription Info:</h4>
                      {product.subscriptionInfo.map((info, idx) => (
                        <div key={idx} className="flex justify-between text-sm">
                          <span className="text-gray-600">{info.key}:</span>
                          <span>{info.value}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CustomerProductVersionsCard;
