import { API_BASE_URL } from '../utils/constants';

const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };
};

export const getCustomerProducts = async (userId) => {
  const response = await fetch(`${API_BASE_URL}/customers/${userId}/products`, {
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to fetch customer products');
  }
  return await response.json();
};

export const createProduct = async (productData) => {
  const response = await fetch(`${API_BASE_URL}/products`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(productData)
  });
  if (!response.ok) {
    throw new Error('Failed to create product');
  }
  return await response.json();
};

export const editProduct = async (productId, productData) => {
  const response = await fetch(`${API_BASE_URL}/products/${productId}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(productData)
  });
  if (!response.ok) {
    throw new Error('Failed to edit product');
  }
  return await response.json();
};

export const getLicenceTemplate = async (productId) => {
  const response = await fetch(`${API_BASE_URL}/products/${productId}/licence-template`, {
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to get licence template');
  }
  return await response.json();
};

export const deleteProduct = async (productId) => {
  const response = await fetch(`${API_BASE_URL}/products/${productId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to delete product');
  }
  return await response.json();
};

export const createProductVersion = (productId, formData, headers) => {
  return fetch(`${API_BASE_URL}/products/${productId}/versions`, {
    method: 'POST',
    headers: {
      ...getAuthHeaders(),
      ...headers
    },
    body: formData
  })
  .then(response => {
    if (!response.ok) {
      if (response.status === 403) {
        throw new Error('Admin access required');
      }
      if (response.status === 404) {
        throw new Error('Product not found');
      }
      return response.json().then(error => {
        throw new Error(error.detail || 'Failed to create product version');
      });
    }
    return response.json();
  });
};

export const editProductVersion = async (productId, versionId, versionData) => {
  const response = await fetch(`${API_BASE_URL}/products/${productId}/versions/${versionId}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(versionData)
  });
  if (!response.ok) {
    throw new Error('Failed to edit product version');
  }
  return await response.json();
};

export const deleteProductVersion = async (productId, versionId) => {
  const response = await fetch(`${API_BASE_URL}/products/${productId}/versions/${versionId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to delete product version');
  }
  return await response.json();
};

export const getProducts = async () => {
  const response = await fetch(`${API_BASE_URL}/products`, {
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to fetch products');
  }
  return await response.json();
};

export const getProductVersions = async (productId) => {
  const response = await fetch(`${API_BASE_URL}/products/${productId}/versions`, {
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to fetch product versions');
  }
  return await response.json();
};

export const downloadSetupFile = async (fileUrl) => {
  const response = await fetch(`${API_BASE_URL}/downloads/${fileUrl}`, {
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to download setup file');
  }
  return await response.json();
};

export const getCustomers = async () => {
  const response = await fetch(`${API_BASE_URL}/customers`, {
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to fetch customers');
  }
  return await response.json();
};

export const createCustomer = async (customerData) => {
  const response = await fetch(`${API_BASE_URL}/customers`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(customerData)
  });
  if (!response.ok) {
    throw new Error('Failed to create customer');
  }
  return await response.json();
};

export const editCustomer = async (customerId, customerData) => {
  const response = await fetch(`${API_BASE_URL}/customers/${customerId}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(customerData)
  });
  if (!response.ok) {
    throw new Error('Failed to edit customer');
  }
  return await response.json();
};

export const deleteCustomer = async (customerId) => {
  const response = await fetch(`${API_BASE_URL}/customers/${customerId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to delete customer');
  }
  return await response.json();
};

export const linkProductVersion = async (customerId, productData) => {
  const response = await fetch(`${API_BASE_URL}/customers/${customerId}/products`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(productData)
  });
  if (!response.ok) {
    throw new Error('Failed to link product version');
  }
  return await response.json();
};

export const editLinkedProductVersion = async (customerId, productId, productData) => {
  const response = await fetch(`${API_BASE_URL}/customers/${customerId}/products/${productId}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(productData)
  });
  if (!response.ok) {
    throw new Error('Failed to edit linked product version');
  }
  return await response.json();
};

export const deleteLinkedProductVersion = async (customerId, productId) => {
  const response = await fetch(`${API_BASE_URL}/customers/${customerId}/products/${productId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to delete linked product version');
  }
  return await response.json();
};

export const getCustomerLinkedProducts = async (customerId) => {
  const response = await fetch(`${API_BASE_URL}/customers/${customerId}/products`, {
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to fetch customer linked products');
  }
  return await response.json();
};

export const getCustomerLinkedProduct = async (customerId, productId) => {
  const response = await fetch(`${API_BASE_URL}/customers/${customerId}/products/${productId}`, {
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to fetch customer linked product');
  }
  return await response.json();
};


export const getEdgeDevices = async () => {
  const response = await fetch(`${API_BASE_URL}/devices`, {
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to fetch edge devices');
  }
  return await response.json();
};


export const setDeviceInstructions = async (hwKey, instructions) => {
  const response = await fetch(`${API_BASE_URL}/device/${hwKey}/instructions`, {
    method: 'POST',
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(instructions)
  });
  if (!response.ok) {
    throw new Error('Failed to set device instructions');
  }
  return await response.json();
};

export const generateLicence = async (siteKey, product, licenceRequest) => {
  const response = await fetch(`${API_BASE_URL}/licence`, {
    method: 'POST',
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      site_key: siteKey,
      product: product,
      licence_request: licenceRequest
    })
  });
  if (!response.ok) {
    throw new Error('Failed to generate licence');
  }
  return await response.json();
};


export const deleteDevice = async (hwKey) => {
  const response = await fetch(`${API_BASE_URL}/device/${hwKey}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to delete device');
  }
  return await response.json();
};


export const generateHardwareLicenceARS = async (customerId, linkedProductId, licenceRequest) => {
  const response = await fetch(`${API_BASE_URL}/customers/generate_hardware_licence`, {
    method: 'POST',
    headers: {
      ...getAuthHeaders(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      customer_id: customerId,
      linked_product_id: linkedProductId,
      licence_request: licenceRequest
    })
  });
  if (!response.ok) {
    throw new Error('Failed to generate hardware licence');
  }
  return await response.json();
};

export const resetHardwareLicenceARS = async (linkedProductId) => {
  const response = await fetch(`${API_BASE_URL}/customers/reset_hardware_licence/${linkedProductId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
  if (!response.ok) {
    throw new Error('Failed to reset hardware licence');
  }
  return await response.json();
};